import * as React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SSRProvider from 'react-bootstrap/SSRProvider'


import DefaultHeader from "../../../../../components/default-header"
import FinAidMenu from "../../../../../components/financialAidMenu"
import Footer from "../../../../../components/footer"
import GdprPanel from "../../../../../components/gdpr"
import Navigation from "../../../../../components/navigation"
import OnlineApplication from "../../../../../components/onlineApplication"
import SpeakWithAdmissions from "../../../../../components/speakWithAdmissions"
import Seo from "../../../../../components/seo"


import '../../../../../styles/basic-content.scss'
import NewsletterSignup from "../../../../../components/newsletterSignUp"

const Page = ({ data }) => {

    const post = data.mdx;
    const iframeSRC = `https://meridianu.formstack.com/forms/impact_scholarship_application`;

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader type="xl" headline="Impact Scholarship Interest Form"  heroBackground={post.frontmatter.heroBackground} heroMobileBackground={post.frontmatter.heroMobileBackground} subHeadline={post.frontmatter.subheadline} />
            <div id="body" className="basic">
                <Container fluid="lg">
                    <Row className="justify-content-center py-4 my-4">
                        <Col md={10} xs={12}>
                            <OnlineApplication src={iframeSRC} width="100%" height="1200" />
                        </Col>
                    </Row>   
                </Container>
                <Container fluid="lg">
                    <div id="bottom-contact">
                        <Row className="py-4 py-xl-5 justify-content-center gx-5">
                            <Col md={6} className="mb-0 mb-md-4 mb-xl-0">
                                <FinAidMenu type="bottom"/>
                            </Col> 
                            <Col md={6} >
                                <SpeakWithAdmissions menuType="bottom-green" className="fin-aid" />   
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = () => (
    <Seo 
        title="Impact Scholarship Interest Form" 
        pathname="/admissions/tuition-and-financial-aid/impact-scholarships/apply"
    />
)

export const query = graphql`
    query ImpactScholarshipApplyPageQuery {
        mdx(fileAbsolutePath: {regex: "/online-application.mdx/"})  {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                }
            }
            body
        }
    }    
`

export default Page